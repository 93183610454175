<template>
  <div class="flex gap-x-4">
    <Button v-if="!excludeSignUp" type="button" v-bind="buttonConfig" :to="{ name: 'auth-sign-up' }" value="회원가입" />
    <Button v-if="!excludeLogin" type="button" v-bind="buttonConfig" :to="{ name: 'auth-login' }" value="로그인" />
  </div>
</template>

<script setup lang="ts">
import type { ButtonProps } from '~/components/ui/button/types'
import type { SignLinkGroupProps } from './types'

defineProps<SignLinkGroupProps>()

const buttonConfig: ButtonProps = {
  as: 'NuxtLink',
  suffixIcon: 'heroicons:chevron-right',
  size: 'lg',
  variant: 'link'
}
</script>
